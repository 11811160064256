import { g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'
import { Avatar as AvatarComponent } from '@pretto/zen/atoms/avatars/Avatar/Avatar'
import styled, { css } from 'styled-components'

export interface ColorProps {
  $color?: string
}

export const AuthorInfos = styled.div`
  display: flex;
  align-items: center;
`
export const Avatar = styled(AvatarComponent)``

export const AuthorInformation = styled.div`
  margin-left: ${g(2)};
`
export const Title = styled.div`
  ${typo.heading16};
`
export const SubTitle = styled.div<ColorProps>`
  ${typo.caption12};

  ${({ $color }) =>
    $color &&
    css`
      color: ${({ theme }) => theme.colors[$color]};
    `}
`
