import { breakpoints, g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'
import { Tooltip as TooltipComponent } from '@pretto/zen/atoms/infos/Tooltip/Tooltip'
import styled from 'styled-components'

export const SharingLinks = styled.div``

export const SharingLinksMobile = styled.div`
  display: flex;
  align-items: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`
export const SharingLinksDesktop = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    align-items: center;
  }
`
export const Title = styled.div`
  ${typo.bodyBook16};
  margin-right: ${g(2)};
`

export const Tooltip = styled(TooltipComponent)`
  & + & {
    margin-left: ${g(2)};
  }
`
