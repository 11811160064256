import { Envelope, Link } from '@pretto/picto'

export const SHARING_METHODES = {
  email: {
    label: 'Email',
    picto: Envelope,
    toolTipText: {
      before: 'Partager par email',
      after: null,
    },
  },
  link: {
    label: 'Lien',
    picto: Link,
    toolTipText: {
      before: 'Copier le lien',
      after: 'Lien copié',
    },
  },
}
