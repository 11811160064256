import { g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const BlocAuthorStart = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${g(2)};
  width: 100%;
`
