interface MailToProps {
  url: string
  title: string
}

export const getEmailMailTo = ({ title, url }: MailToProps) => {
  const subject = `🤓 A lire sur Pretto : ${title}`
  const body = `Je viens de découvrir cet article sur Pretto et je pense qu'il t'intéressera !

💡 ${title}

Lire l'article complet sur : ${url}`

  return `mailto:?subject=${subject}&body=${encodeURIComponent(body)}`
}
