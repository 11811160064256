import { AuthorInfos, AuthorInfosProps } from '@pretto/zen/seo/blocAuthor/AuthorInfos/AuthorInfos'
import { SharingLinks, SharingLinksProps } from '@pretto/zen/seo/blocAuthor/SharingLinks/SharingLinks'

import * as S from './BlocAuthorStart.styles'

export interface BlocAuthorStartProps {
  authorProps: AuthorInfosProps
  sharingProps: SharingLinksProps
}

export const BlocAuthorStart = ({ authorProps, sharingProps, ...props }: BlocAuthorStartProps) => (
  <S.BlocAuthorStart {...props}>
    <AuthorInfos {...authorProps} subTitleColor={'neutral2'} />
    <SharingLinks {...sharingProps} />
  </S.BlocAuthorStart>
)
