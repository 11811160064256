import Wrapper from '@pretto/bricks/components/layout/Wrapper'
import ContentHero from '@pretto/bricks/website/shared/components/ContentHero'

import Ebook from '../components/Ebook'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

const NotFoundPage = () => (
  <Layout>
    <Wrapper>
      <ContentHero title="Page introuvable" titleAlignment="center" />
    </Wrapper>

    <Ebook />

    <SEO noindex title="Page introuvable - Pretto" />
  </Layout>
)

export default NotFoundPage
