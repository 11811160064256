import Heading from '@pretto/bricks/components/typography/Heading'

import { BlocAuthorStart } from '@pretto/zen/seo/blocAuthor/BlocAuthorStart/BlocAuthorStart'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const ContentHero = ({ blocAuthorStartProps, image, title, titleAlignment }) => (
  <S.Hero>
    {title && (
      <S.Title align={titleAlignment}>
        <Heading size="large" type="h1">
          {title}
        </Heading>
      </S.Title>
    )}

    {blocAuthorStartProps && (
      <S.Wrapper>
        <BlocAuthorStart {...blocAuthorStartProps} />
      </S.Wrapper>
    )}

    {image && <S.Image alt={title} path={image} />}
  </S.Hero>
)

ContentHero.defaultProps = {
  titleAlignment: 'left',
}

ContentHero.propTypes = {
  /** Props of <code>[BlocAuthorStart](/#!/blocAuthorStart)</code> components. */
  blocAuthorStartProps: PropTypes.object,
  /** Slug of the hero image. */
  image: PropTypes.string,
  /** Title of the content. */
  title: PropTypes.string,
  /** Alignment of the title. */
  titleAlignment: PropTypes.oneOf(['center', 'left']),
}

export default memo(ContentHero)
