import { breakpoints, g, numeralBreakpoints } from '@pretto/bricks/components/layout'
import BaseImage from '@pretto/bricks/website/shared/components/Image'

import styled from 'styled-components'

export const Hero = styled.div`
  position: relative;
`

export const Image = styled(BaseImage).attrs({
  options: { aspectRatio: '16:9', crop: 'fill' },
  sizes: `(min-width: ${breakpoints.desktop}) ${breakpoints.tablet}, (min-width: ${breakpoints.tablet}) ${
    100 * (2 / 3)
  }vw, 100vw`,
  srcSetBreakpoints: [
    numeralBreakpoints.mobileS,
    numeralBreakpoints.mobileM,
    numeralBreakpoints.mobileL,
    numeralBreakpoints.tablet,
    numeralBreakpoints.laptop,
    numeralBreakpoints.desktop,
  ],
})`
  border-radius: ${g(1 / 2)};
  display: block;
  width: 100%;
`

export const Title = styled.div`
  margin-bottom: ${g(4)};
  text-align: ${({ align }) => align};
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${g(4.5)};
  align-items: center;
`

export const ShareButtons = styled.div`
  display: flex;

  > :first-child {
    margin-right: ${g(2)};
  }
`
