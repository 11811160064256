import { AvatarProps } from '@pretto/zen/atoms/avatars/Avatar/Avatar'

import * as S from './AuthorInfos.styles'

export interface AuthorInfosProps {
  avatarProps: AvatarProps
  title: string
  subTitle: string
  subTitleColor?: string
}

export const AuthorInfos = ({ avatarProps, subTitle, subTitleColor, title, ...props }: AuthorInfosProps) => (
  <S.AuthorInfos {...props}>
    <S.Avatar {...avatarProps} />
    <S.AuthorInformation>
      <S.Title>{title}</S.Title>
      <S.SubTitle $color={subTitleColor}>{subTitle}</S.SubTitle>
    </S.AuthorInformation>
  </S.AuthorInfos>
)
